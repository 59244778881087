<template>
	<section id="HowTo">
		<div class="container-fluid">
			<div class="mx-auto my-5 text-center" style="max-width: 770px">
				<h2 class="section-title">Como integrar o <span class="pixem">PIX</span> ao meu sistema?</h2>
				<p class="subtitle mx-md-5 mt-3">A WIPag disponibiliza um conjunto de API's de rápida integração ao seu sistema</p>
			</div>

			<div class="row justify-content-center py-5">
				<div class="col-md-4 col-xl-3 col-xxl-2 mb-md-0 mb-5">
					<div class="howTo">
						<span class="step">1</span>
						<div class="icon"><font-awesome-icon fixed-width :icon="['fal', 'file-check']" /></div>
						<p>Solicite a documentação de integração</p>
					</div>
				</div>
				<div class="col-md-4 col-xl-3 col-xxl-2 my-md-0 my-5">
					<div class="howTo">
						<span class="step">2</span>
						<div class="icon"><font-awesome-icon fixed-width :icon="['fal', 'laptop-code']" /></div>
						<p>Integre e homologue sua plataforma</p>
					</div>
				</div>
				<div class="col-md-4 col-xl-3 col-xxl-2 mt-md-0 mt-5">
					<div class="howTo">
						<span class="step">3</span>
						<div class="icon"><font-awesome-icon fixed-width :icon="['fal', 'handshake']" /></div>
						<p>Disponibilize para seus clientes a solução PIX e alavanque suas vendas</p>
					</div>
				</div>
			</div>
			<div class="text-center mt-5">
				<a href="https://wa.me/5515981895247/?text=Ol%C3%A1+WI+Pag%2C+gostaria+de+integrar+seu+serviço" class="btn rounded-pill btn-custom" target="_blank" rel="noopener noreferrer">Integre agora</a>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'HowTo',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	section {
		--paragraph-min: 18;
		--paragraph-max: 18;

		background-color: #ffffff;
		background-image: url('../assets/img/bghow.png');
		background-repeat: no-repeat;
		background-position: center bottom;
		background-size: contain;
	}
	.btn-custom {
		--padding-x: 50px;
		--padding-y: 10px;
	}
	.howTo {
		border: 3px solid #000000;
		border-radius: 50px;
		position: relative;
		background-color: #ffffff;
		padding: 20px;
		padding-top: 50px;
		min-height: 290px;
		.icon { 
			font-size: 100px;
			text-align: center;
		}
		.step {
			position: absolute;
			background-color: #000000;
			border-radius: 50%;
			color: var(--pixcolor);
			text-align: center;
			width: 70px;
			height: 70px;
			line-height: 70px;
			font-size: 60px;
			font-weight: 900;
			top: -35px;
			left: 50%;
			transform: translateX(-50%);
			padding: 0 10px;
		}
		p {
			color: #6e6e6e;
			margin: 0;
			line-height: 1.2;
			text-align: center;
		}
		transition: all .15s ease;
		&:hover {
			transform: scale(1.015);
			box-shadow: 0 0 16px rgba(20,255,81,.55);
			p {
				color: #000000;
			}
		}
	}
</style>
