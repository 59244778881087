<template>
	<section id="CallOut">
		<div class="container text-center">
			<h3 class="section-title">PIX WIPag</h3>
			<p><strong>Integre agora</strong> e receba de forma instantânea os pagamentos da sua empresa</p>

			<a href="https://wa.me/5515981895247/?text=Ol%C3%A1+WI+Pag%2C+gostaria+de+integrar+seu+serviço" class="btn rounded-pill btn-custom my-5" target="_blank" rel="noopener noreferrer">Clique aqui</a>

			<p>E agende uma reunião com nosso time</p>
		</div>
	</section>
</template>

<script>
export default {
	name: 'CallOut',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	section {
		--title-min: 45;
		--title-max: 88;
		--paragraph-min: 18;
		--paragraph-max: 28;
	}
	.container {
		max-width: 720px;
	}
</style>
