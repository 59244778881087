<template>
	<Hero />
	<About />
	<WhyBest />
	<HowTo />
	<Benefits />
	<CallOut />
	<Footer />
</template>

<script>
	import Hero from './components/Hero.vue'
	import About from './components/About.vue'
	import WhyBest from './components/WhyBest.vue'
	import HowTo from './components/HowTo.vue'
	import Benefits from './components/Benefits.vue'
	import CallOut from './components/CallOut.vue'
	import Footer from './components/Footer.vue'

	export default {
		name: 'App',
		components: {
			Hero,
			About,
			WhyBest,
			HowTo,
			Benefits,
			CallOut,
			Footer,
		}
	}
</script>

<style lang="scss">

</style>
