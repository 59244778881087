<template>
	<section id="Hero">
		<div class="container text-center">
			<a href="https://pix.widigital.com.br" target="_blank" rel="noopener noreferrer" class="btn rounded-pill btn-custom login">Login</a>

			<a href="/" title="PIX WIPag - Tecnologia para meios de pagamentos">
				<img src="../assets/img/logo-wipag.png" alt="Logo WIPag" srcset="../assets/img/logo-wipag@2x.png 2x">
			</a>

			<div class="mx-auto my-5 py-5" style="max-width: 850px;">
				<h1 class="section-title">Tecnologia para meios de pagamentos</h1>
				<p>Segurança e Velocidade</p>
			</div>

			<div class="text-center">
				<a href="#WhyBest" class="btn rounded-pill btn-custom readmore">Leia mais</a>
			</div>

		</div>
		<div class="text-center">
			<font-awesome-icon fixed-width :icon="['fal', 'chevron-double-down']" :class="'icon my-5'" />
		</div>
	</section>
</template>

<script>
	export default {
		name: 'Hero',
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	section {
		--padding-y: 70px;
		background-image: url('../assets/img/bghero.jpg');
		background-repeat: no-repeat;
		background-position: center top;
		background-size: cover;
		position: relative; 
		color: #fff;
		min-height: 900px;
		p { color: #9c9c9c; }
		.container { position: relative; }
		.icon {
			// position: absolute;
			// bottom: 110px;
			// left: 50%;
			// transform: translateX(-50%);
			font-size: 40px;
		}
	}
	.btn-custom {
		box-shadow: 0px 0px 15px 0px rgba(255,255,255, .75);

		&.login {
			--color: #ffffff;
			--color-hover: #000000;
			--background: transparent;
			--background-hover: var(--color);
			--border-color: #ffffff;
			--border-hover: var(--border-color);
			--padding-y: 8px;
			--padding-x: 55px;
			--font-size: 16px;
			--shadow: 255,255,255;

			position: absolute;
			top: 0;
			right: 15px;

			@media (max-width: 560px) {
				top: -55px;
			}
			@media (max-width: 768px) {
				--padding-x: 25px;
			}
		}
		&.readmore {
			--color: #000000;
			--color-hover: #ffffff;
			--background: #ffffff;
			--background-hover: transparent;
			--border-hover: #ffffff;
			--padding-y: 12px;
			--padding-x: 65px;
			--font-size: 16px;
			--shadow: 255,255,255;
		}
	}
</style>
