<template>
	<section id="WhyBest">
		<div class="container">
			<h2 class="px-md-5  m-md-5 section-title text-center">Porque o PIX é a melhor opção <span class="pixem">para a minha</span> empresa?</h2>
			<div class="row pt-5">
				<div class="col-md-6 mb-4 px-4 px-md-0">
					<p>Com os meios de pagamentos tradicionais você tem <strong>altas taxas</strong>, lentidão na transmissão de informações e espera para compensação dos valores recebidos.</p>
					<p><b>Além da quantidade de dados que o seu cliente tem que informar no momento de ralizar um pagamento.</b></p>

					<p>Com o <strong>PIX</strong>, o pagamento de pedidos passa a ser uma <strong>experiência ágil</strong>, segura e veloz tanto para você como para o seu cliente.</p>
					<p>Para pagar precisa apenas <strong>copiar e colar</strong> o código ou simplesmente escanear o <strong>QR Code</strong>.</p>

					<p>Os recursos recebidos entram em tempo real na conta da sua empresa.</p>
				</div>
				<div class="col-md-6 text-center">
					<img src="../assets/img/qrcode.png" alt="" class="img-fluid">
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'WhyBest',
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	section {
		--paragraph-min: 16;
		--paragraph-max: 18;
		--pixem-width: 105%;
		--pixem-height: 105%;
		--pixemT: 2%;
		--pixemL: -3%;

		background-color: #ffffff;
		background-image: url('../assets/img/bgwhy.jpg');
		background-repeat: no-repeat;
		background-position: center 100px;
		position: relative;

		&:after {
			content: '';
			position:absolute;
			background-color: #000000;
			width: 100%;
			max-width: 300px;
			height: 5px;
			border-radius: 10px;
			transform: skewX(-50deg) translateX(-50%);
			left: 50%;
			bottom: 0;
		}
	}
</style>
