<template>
	<section class="container-fluid text-center">
		<img src="../assets/img/o-que-e-pix.png" alt="O que é o PIX?" class="img-fluid mx-auto">
		<div class="row mt-3 justify-content-center">
			<div class="col-md-6 text-white px-4 px-md-0">
				<p>O <strong>PIX</strong> é o novo meio de pagamento instantâneo criado pelo Banco Central (BC), em que os valores são transferidos de forma rápida e sem burocracia, <strong>a qualquer hora ou dia</strong>.</p>	
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'About',
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	section {
		--paragraph-min: 18;
		--paragraph-max: 28;
		
		background-color: #1a1a1a;
		background-image: url('../assets/img/bgabout.png');
		background-repeat: no-repeat;
		background-position: center top;
		position: relative;

		&:before, &:after {
			position: absolute;
			background-repeat: repeat-x;
			content: '';
			left: 0;
			z-index: 3;
		}
		&::before {
			width: 100%;
			height: 110px;
			background-image: url('../assets/img/wave1.png');
			background-position: center top;
			top: -110px;
		}
		&::after {
			width: 100%;
			height: 80px;
			background-image: url('../assets/img/wave2.png');
			background-position: center top;
			bottom: -80px;
		}
	}
</style>
