<template>
	<footer>
		<div class="container">
			<div class="row">
				<div class="col-md-6 col-lg-3 mb-5 mb-lg-0">
					<img src="../assets/img/logo-wipag.png" alt="" srcset="../assets/img/logo-wipag@2x.png 2x" width="125" height="54">

					<address class="my-5">
						Rua Emigdia Campolim, 243 <br/>
						Parque Campolim, Sorocaba / SP <br/>
						18047-626
					</address>

					<img src="../assets/img/aws-partner-network.png" srcset="../assets/img/aws-partner-network@2x.png 2x" alt="Amazon Web Services Partner Network">
				</div>
				<div class="col-md-6 col-lg-3 mx-auto mb-5 mb-lg-0">
					<nav class="d-inline-flex flex-column">
						<a href="https://widigital.freshdesk.com/support/login" target="_blank" rel="noopener noreferrer" class="mb-3"><i class="fal fa-fw fa-user-headset"></i> Suporte</a>

						<a href="https://www.widigital.com.br/sobre-widigital?ref=wipag" class="mb-1" target="_blank" rel="noopener noreferrer" title="A WI Digital">A WI Digital</a>
						<a href="https://www.widigital.com.br/clientes?ref=wipag" class="mb-1" target="_blank" rel="noopener noreferrer" title="Clientes">Clientes</a>
						<a href="https://www.widigital.com.br/termos-de-uso?ref=wipag" class="mb-1" target="_blank" rel="noopener noreferrer" title="Termos de Uso">Termos de Uso</a>
						<a href="https://www.widigital.com.br/politica-de-privacidade?ref=wipag" class="mb-1" target="_blank" rel="noopener noreferrer" title="Política de Privacidade">Política de Privacidade</a>
					</nav>
				</div>
				<div class="col-lg-4 ml-auto mb-5 mb-lg-0">
					<strong class="d-block mb-4"><a href="https://www.instagram.com/widigital/" target="_blank" rel="noopener noreferrer" title="WI Digital no Instagram">
						<font-awesome-icon fixed-width :icon="['fab', 'instagram']" /> Instagram</a>
					</strong>

					<address>
						<p class="mb-4">
							<strong class="d-block mb-1"><font-awesome-icon fixed-width :icon="['fab', 'whatsapp']" /> Whatsapp (Comercial)</strong>
							<a href="https://wa.me/5515981895247/?text=Ol%C3%A1+WI+Digital%2C+gostaria+de+entrar+em+contato" class="h4" title="Fale conosco no Whatsapp" target="_blank" rel="noopener noreferrer">+55 (15) 98189-5247</a>
						</p>
						<p class="mb-4">
							<strong class="d-block mb-1"><font-awesome-icon fixed-width :icon="['fal', 'phone-alt']" /> Atendimento</strong>
							<a href="tel:08005916269" class="h4" title="Atendimento">0800 591 6269</a>
						</p>
						<p class="mb-4">
							<strong class="d-block mb-1"><font-awesome-icon fixed-width :icon="['fal', 'paper-plane']" /> E-mail</strong>
							<a href="mailto:comercial@widigital.com.br" title="Entre em contato" class="h4 text-truncate d-block">comercial@widigital.com.br</a>
						</p>
						<p>Segunda à Sexta das 9h às 18h, exceto feriados.</p>
					</address>
				</div>
			</div>

			<div class="text-center copyright">
				WIPag - Tecnologia para meios de pagamentos <font-awesome-icon fixed-width :icon="['fal', 'copyright']" /> 2021 <br/>
				WIPag é uma marca registrada da <a href="http://www.widigital.com.br?ref=wipag" target="_blank" rel="noopener noreferrer" title="WI Digital - Software para Marketing Multinível">WI Digital</a>.
			</div>
		</div>

	</footer>
	<a onclick="return gtag_report_conversion('https://wa.me/5515981895247/?text=Ol%C3%A1+WI+Pag%2C+gostaria+de+entrar+em+contato');" href="https://wa.me/5515981895247/?text=Ol%C3%A1+WI+Pag%2C+gostaria+de+entrar+em+contato" title="Fale conosco agora!" target="_blank" rel="noopener noreferrer" class="shadow whatsapp-bubble">
		<font-awesome-icon fixed-width :icon="['fab', 'whatsapp']" /> <span class="d-none d-sm-inline">Fale conosco</span>
	</a>
</template>

<script>
	export default {
		name: 'Footer',
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	footer {
		background-image: url('../assets/img/bgfooter.jpg');
		background-repeat: no-repeat;
		background-position: center top;
		background-size: cover;
		
		position: relative;
		padding: 30px;
		padding-top: 100px;
		min-height: 279px;

		color: #ffffff;

		.h4 {
			font-weight: 900;
		}

		a {
			color: #ffffff;
			transition: all .15s ease-in-out;
			font-weight: 700;
			text-decoration: none;

			&:hover, &:focus {
				text-decoration: underline;
				color: #ffffff;
			}
		}

		strong {
			font-size: 18px;
		}

		.copyright {
			font-size: 14px;
		}

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 52px;
			background-image: url('../assets/img/wave3.png');
			background-repeat: repeat-x;
			background-position: center top;
		}
	}
</style>
