import { createApp } from 'vue'
import App from './App.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import { faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons'

library.add(fal, faWhatsapp, faInstagram)

//  bs js
// import bootstrap from 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/fonts/inter.css'
import './assets/style.scss'

createApp(App)
    .component("font-awesome-icon", FontAwesomeIcon)
    .component('font-awesome-layers', FontAwesomeLayers)
    .mount('#app')
