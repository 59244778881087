<template>
	<section id="Benefits">
		<div class="container">
			<div class="mx-auto mb-3 mb-md-5 text-center">
				<h2 class="section-title">Quais os benefícios do <br/> <span class="emphasis">PIX WIPag</span> para a empresa?</h2>
			</div>

			<div class="row justify-content-center pt-3 pt-md-5">
				<div class="col-md-6 col-xxl-4 mb-3">
					<div class="benefit">
						<div class="icon">
							<font-awesome-icon fixed-width :icon="['fal', 'hand-holding-usd']"/>
						</div>
						<div>
							<strong>Recebimento instantâneo</strong>
							<p>Recursos disponíveis em tempo real</p>
						</div>
					</div>
				</div>
				<div class="col-md-6 col-xxl-4 mb-3">
					<div class="benefit">
						<div class="icon">
							<font-awesome-icon fixed-width :icon="['fal', 'code-merge']"/>
						</div>
						<div>
							<strong>Implementação Plug and Play</strong>
							<p>API já homologada e de fácil integração</p>
						</div>
					</div>
				</div>
				<div class="col-md-6 col-xxl-4 mb-3">
					<div class="benefit">
						<div class="icon">
							<font-awesome-icon fixed-width :icon="['fal', 'hands-helping']"/>
						</div>
						<div>
							<strong>Conciliação</strong>
							<p>Sistema específico para conciliação de pagamentos</p>
						</div>
					</div>
				</div>
				<div class="col-md-6 col-xxl-4 mb-3">
					<div class="benefit">
						<div class="icon">
							<font-awesome-layers class="fa-lg">
								<font-awesome-icon fixed-width :icon="['fal', 'qrcode']" transform="shrink-3 up-1 right-3"/>
								<font-awesome-icon :icon="['fal', 'mobile-android']" transform="down-5"/>
							</font-awesome-layers>
							
						</div>
						<div>
							<strong>QR code grátis</strong>
							<p>Sem taxa de emissão</p>
						</div>
					</div>
				</div>
				<div class="col-md-6 col-xxl-4 mb-3">
					<div class="benefit">
						<div class="icon">
							<font-awesome-icon fixed-width :icon="['fal', 'shield-check']"/>
						</div>
						<div>
							<strong>Ambiente seguro</strong>
							<p>Integrado ao Banco Central do Brasil</p>
						</div>
					</div>
				</div>
				<div class="col-md-6 col-xxl-4 mb-3">
					<div class="benefit">
						<div class="icon">
							<font-awesome-layers class="fa-lg">
								<font-awesome-icon :icon="['fal', 'ban']" />
								<font-awesome-icon :icon="['fal', 'dollar-sign']" transform="shrink-7" />
							</font-awesome-layers>
						</div>
						<div>
							<strong>Setup custo zero</strong>
							<p>Sem cobrança de taxa para integração</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'Benefits',
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	section {
		--padding-y: 115px;
		--paragraph-min: 16;
		--paragraph-max: 20;

		background-color: #000;
		background-image: url('../assets/img/bgbenefits.png');
		background-repeat: no-repeat;
		background-position: center bottom;
		background-size: cover;
		position: relative;
		color: #fff;

		.emphasis { color: var(--pixcolor) }
	}


	.benefit {
		display: flex;
		font-size: 20px;

		.icon, strong { color: var(--pixcolor) }
		.icon {
			font-size: 30px;
			padding: 0 1.5rem
		}
	}
</style>
